import { CERTIFICATE_ID } from '@/utils/constants.js';

export const assetsColumns = [
  {
    key: 'id',
    heading: 'ID',
    shouldHide: true,
    isDraggable: false,
    isRightAligned: false,
    fixedCol: true
  },
  {
    key: 'type',
    heading: 'Asset',
    sortable: true,
    filter: true,
    isDraggable: false,
    shouldHide: false,
    isResizable: false,
    className: 'sticky left-0 asset--heading',
    component: {
      name: 'SvgSprite',
      icon: null,
      iconClass: 'w-5 h-5 fill-white outline-none',
      isModel: false
    },
    colorClasses: {
      P: 'bg-pink-50',
      A: 'bg-green-400'
    },
    isRightAligned: false,
    fixedCol: true
  },
  {
    key: 'source',
    sortable: true,
    isDraggable: false,
    shouldHide: false,
    heading: 'Project/Basket Name',
    // className: 'sticky w-16',
    isRightAligned: false,
    isResizable: true,
    fixedCol: true
  },
  {
    key: 'asset_unit',
    heading: 'Unit',
    sortable: true,
    isDraggable: true,
    shouldHide: false,
    colorClasses: {
      MMBTU: 'bg-green-400'
    },
    formatValue: (value) => value,
    isRightAligned: false
  },
  {
    key: 'is_transferred',
    heading: 'Source',
    sortable: true,
    shouldHide: true,
    isDraggable: true,
    isRightAligned: false
  },

  {
    key: 'created_date',
    heading: 'Created Date',
    sortable: true,
    isDraggable: true,
    shouldHide: true,
    isRightAligned: false
  },
  {
    key: 'updated_date',
    heading: 'Updated Date',
    sortable: true,
    isDraggable: true,
    shouldHide: false,
    isRightAligned: false
  },
  {
    key: 'total_available_qty',
    heading: 'Available Qty.',
    shouldHide: false,
    sortable: true,
    isDraggable: true,
    isRightAligned: true
  },
  {
    key: 'total_posted_qty',
    heading: 'Posted Qty.',
    columnColorClass: 'bg-pink-50',
    shouldHide: false,
    sortable: true,
    isDraggable: true,
    isRightAligned: true
  },
  {
    key: 'total_pending_qty',
    heading: 'Pending Transfer Qty.',
    shouldHide: false,
    sortable: true,
    isDraggable: true,
    isRightAligned: true
  },
  // {
  //   key: 'total_transfered_qty',
  //   heading: 'Transferred Qty.',
  //   sortable: true,
  //   isDraggable: true,
  //   shouldHide: true,
  //   isRightAligned: true
  // },
  // {
  //   key: 'total_retired_qty',
  //   heading: 'Retired Qty.',
  //   sortable: true,
  //   isDraggable: true,
  //   shouldHide: false,
  //   isRightAligned: true
  // }
];

export const assetInventoryInnerCols = [
  {
    key: 'vintage',
    isResizable: true,
    heading: CERTIFICATE_ID,
    className: 'sticky left-0 asset--heading bg-gray-50',
    isRightAligned: false,
    hasLowerCase: true
  },
  {
    key: 'cert_type',
    isResizable: true,
    heading: 'Segment',
    isRightAligned: false
  },
  {
    key: 'credited_date',
    heading: 'Credited Date',
    isRightAligned: true
  },
  {
    key: 'total_available_qty',
    heading: 'Available Qty.',
    className: 'text-right',
    isRightAligned: true
  },
  {
    key: 'total_posted_qty',
    heading: 'Posted Qty.',
    className: 'text-right',
    isRightAligned: true
  },
  {
    key: 'total_pending_qty',
    heading: 'Pending Qty',
    className: 'text-right',
    isRightAligned: true
  },
  // {
  //   key: 'total_transfered_qty',
  //   heading: 'Transfer Qty.',
  //   className: 'text-right',
  //   isRightAligned: true
  // },
  // {
  //   key: 'total_retired_qty',
  //   heading: 'Retired Qty.',
  //   className: 'text-right',
  //   isRightAligned: true
  // }
];

export const projectItemsColumns = [
  {
    key: 'id',
    heading: 'ID',
    shouldHide: true
  },
  {
    key: 'type',
    heading: 'Asset Type',
    component: {
      name: 'SvgSprite',
      icon: null,
      iconClass: 'w-5 h-5 fill-white outline-none',
      isModel: false,
      tooltip: true,
      tooltip_key: 'asset_name'
    }
  },
  {
    key: 'cert_type',
    heading: 'Segment',
    sortable: true,
    shouldHide: false,
    isDraggable: true,
    isRightAligned: false
  },
  {
    key: 'asset_unit',
    heading: 'Unit'
  },
  {
    key: 'asset_source',
    heading: 'Source'
  },

  {
    key: 'source',
    heading: 'Project/Basket Name'
  },
  {
    key: 'vintage',
    // heading: 'Vintage',
    heading: CERTIFICATE_ID,
    formatValue: (value) => (value ? value : '-')
  },
  {
    key: 'methodology_sort_name',
    heading: 'Methodology'
  },
  {
    key: 'total_available_qty',
    heading: 'Available Qty.'
  }
];

export const updatedBasektPopupData = [
  {
    key: 'id',
    heading: 'ID',
    shouldHide: true
  },
  {
    key: 'type',
    heading: 'Asset Type',
    component: {
      name: 'SvgSprite',
      icon: null,
      iconClass: 'w-5 h-5 fill-white outline-none',
      isModel: false,
      tooltip: true,
      tooltip_key: 'asset_name'
    }
  },
  {
    key: 'asset_unit',
    heading: 'Unit'
  },
  {
    key: 'is_transferred',
    heading: 'Source'
  },

  {
    key: 'source',
    heading: 'Project/Basket Name'
  },
  {
    key: 'available_qty',
    heading: 'Available Qty.'
  },

  {
    key: 'quantity',
    heading: 'Qty. in Basket'
  },
  {
    key: 'calculatedEmission',
    heading: 'CO<sub>2</sub>e Emissions (MT)'
  }
];

export const updateBasketColumns = [
  {
    key: 'id',
    heading: 'ID',
    shouldHide: true
  },
  {
    key: 'type',
    heading: 'Asset Type',
    component: {
      name: 'SvgSprite',
      icon: null,
      iconClass: 'w-5 h-5 fill-white outline-none',
      isModel: false,
      tooltip: true,
      tooltip_key: 'asset_name'
    }
  },
  {
    key: 'asset_unit',
    heading: 'Unit'
  },
  {
    key: 'is_transferred',
    heading: 'Source'
  },

  {
    key: 'source',
    heading: 'Project/Basket Name'
  },
  {
    key: 'vintage',
    // heading: 'Vintage',
    heading: CERTIFICATE_ID,
    formatValue: (value) => (value ? value : '-')
  },
  {
    key: 'methodology_sort_name',
    heading: 'Methodology'
  },
  {
    key: 'total_available_qty',
    heading: 'Available Qty.'
  }
];
export const basketDetailColumns = [
  {
    key: 'id',
    heading: 'ID',
    shouldHide: true
  },
  {
    key: 'type',
    heading: 'Asset Type',

    component: {
      name: 'SvgSprite',
      icon: null,
      iconClass: 'w-5 h-5 fill-white outline-none',
      isModel: false,
      tooltip: true,
      tooltip_key: 'asset_name'
    }
  },

  {
    key: 'cert_type',
    heading: 'Segment',
    sortable: true,
    shouldHide: false,
    isDraggable: true,
    isRightAligned: false
  },
  {
    key: 'asset_unit',
    heading: 'Unit',
    className: 'text-gray-700 text-sm 2xl:text-base'
  },
  {
    key: 'asset_source',
    heading: 'Source',
    className: 'text-gray-700 text-sm 2xl:text-base'
  },

  {
    key: 'source',
    heading: 'Project/Basket Name',
    className: 'text-gray-700 text-sm 2xl:text-base'
  },
  {
    key: 'vintage',
    // heading: 'Vintage',
    heading: CERTIFICATE_ID,
    className: 'text-gray-700 text-sm 2xl:text-base',
    formatValue: (value) => value
  },
  {
    key: 'methodology_sort_name',
    heading: 'Methodology',
    className: 'text-gray-700 text-sm 2xl:text-base'
  },
  {
    key: 'basketedQuantity',
    heading: 'Qty.. in Basket',
    className: 'text-gray-700 text-sm 2xl:text-base'
  },
  {
    key: 'emissions',
    heading: 'CO2e Emissions (MT)',
    className: 'text-gray-700 text-sm 2xl:text-base'
  }
];

export const basketSummaryDetailColumns = [
  {
    key: 'id',
    heading: 'ID',
    shouldHide: true
  },
  {
    key: 'type',
    heading: 'Asset Type',

    component: {
      name: 'SvgSprite',
      icon: null,
      iconClass: 'w-5 h-5 fill-white outline-none',
      isModel: false,
      tooltip: true,
      tooltip_key: 'asset_name'
    }
  },
  {
    key: 'asset_unit',
    heading: 'Unit',
    className: 'text-gray-700 text-sm 2xl:text-base'
  },
  {
    key: 'is_transferred',
    heading: 'Source',
    className: 'text-gray-700 text-sm 2xl:text-base'
  },

  {
    key: 'source',
    heading: 'Project/Basket Name',
    className: 'text-gray-700 text-sm 2xl:text-base'
  },
  {
    key: 'vintage',
    // heading: 'Vintage',
    heading: CERTIFICATE_ID,
    className: 'text-gray-700 text-sm 2xl:text-base',
    formatValue: (value) => value
  },
  {
    key: 'methodology_sort_name',
    heading: 'Methodology',
    className: 'text-gray-700 text-sm 2xl:text-base'
  },
  {
    key: 'basketedQuantity',
    heading: 'Qty.. in Basket',
    className: 'text-gray-700 text-sm 2xl:text-base'
  },
  {
    key: 'emissions',
    heading: 'CO2e Emissions (MT)',
    className: 'text-gray-700 text-sm 2xl:text-base'
  }
];

export const retirementCols = [
  {
    key: 'id',
    heading: 'Retirement Id',
    className: 'text-gray-700 text-sm 2xl:text-base'
  },
  {
    key: 'type',
    heading: 'Asset Type',
    className: 'text-gray-700 text-sm 2xl:text-base',
    component: {
      name: 'SvgSprite',
      icon: null,
      iconClass: 'w-5 h-5 fill-white outline-none',
      isModel: false,
      tooltip: true,
      tooltip_key: 'asset_name'
    }
  },

  {
    key: 'cert_type',
    heading: 'Segment',
    sortable: true,
    shouldHide: false,
    isDraggable: true,
    isRightAligned: false
  },
  {
    key: 'asset_unit',
    heading: 'Unit',
    className: 'text-gray-700 text-sm 2xl:text-base'
  },
  {
    key: 'source',
    heading: 'Project Name',
    className: 'text-gray-700 text-sm 2xl:text-base'
  },
  {
    key: 'vintage',
    // heading: 'Vintage',
    heading: CERTIFICATE_ID,
    className: 'text-gray-700 text-sm 2xl:text-base',
    formatValue: (value) => value
  },
  {
    key: 'newRetiredQty',
    heading: 'Retired Qty.',
    className: 'text-gray-700 text-sm 2xl:text-base',
    shouldHide: true
  }
];
